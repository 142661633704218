<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="New order" bold />
      <div class="ml-auto">
        <SButtonCreateOrder
          color="primary"
          @click="create"
          :options="{ disabled: disabled }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow class="mb-2">
        <CCol col="12" lg="6" class="mb-3">
          <SSelectCustomer
            @update:value="data.customer_id = $event"
            label="Customer"
            class="my-3"
          />
          <SSelectShipmentMethodBox
            :value.sync="data.shipment_method_id"
            @update:value="data.shipment_method_id = $event"
          />
          <CRow class="mt-3">
            <CCol col="12" md="6">
              <SSelectOrganization
                :value.sync="data.sales_organization_id"
                :prepend="[]"
                label="Purchasing company"
              />
            </CCol>
            <CCol col="12" md="6">
              <SSelectOrganization
                :value.sync="data.transport_organization_id"
                :prepend="[]"
                label="Transportation company"
              />
            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol col="12" md="6">
              <SSelectCurrency
                label="Currency type of cost of goods"
                :value.sync="data.purchase_cost_currency_id"
                :prepend="[]"
                class="mb-3"
              />
            </CCol>
            <CCol col="12" md="6">
              <SSelectCurrency
                label="Currency type of service fee"
                :value.sync="data.service_fee_currency_id"
                :prepend="[]"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            placeholder="Note"
            label="Note"
            class="mb-3"
            @update:value="data.note = $event"
          />
        </CCol>
        <CCol col="12" lg="6" class="mb-3">
          <SSelectShipmentInfo
            :userId="data.customer_id"
            @update:value="shipment_info = { ...$event }"
            class="my-3 mb-4"
          />
          <div class="border-primary rounded p-1 px-3">
            <TTableAsForm
              v-if="shipment_info"
              :data="shipment_info"
              :fields="shipmentFields"
              :splitLeft="5"
              class="p-1"
            >
              <template #consignee="{ value }">
                <TMessageText
                  :value="value"
                  editable
                  @change="shipment_info.consignee = $event"
                />
              </template>
              <template #full_address="{ data }">
                <SMessageAddress
                  :address="data.address_detail"
                  :wardId="data.ward_id"
                  :content="data.full_address"
                  editable
                  @change="updateAddress"
                />
              </template>
              <template #tel="{ value }">
                <TMessageText
                  :value="value"
                  editable
                  @change="shipment_info.tel = $event"
                />
              </template>
              <template #sender_name="{ value }">
                <TMessageText
                  :value="value"
                  editable
                  @change="shipment_info.sender_name = $event"
                />
              </template>
              <template #sender_full_address="{ data }">
                <SMessageAddress
                  :address="data.sender_address || ''"
                  :wardId="data.sender_ward_id || ''"
                  :content="data.sender_full_address || ''"
                  editable
                  @change="updateSenderAddress"
                />
              </template>
              <template #sender_tel="{ value }">
                <TMessageText
                  :value="value"
                  editable
                  @change="shipment_info.sender_tel = $event"
                />
              </template>
              <template #note="{ value }">
                <TMessageText
                  :value="value"
                  editable
                  @change="shipment_info.note = $event"
                />
              </template>
            </TTableAsForm>
            <TMessageNoData v-else />
          </div>
          <TInputFile
            v-if="withAttachment"
            @update:value="file = $event[0].file"
            :multiple="false"
            label="Order file"
            class="my-3"
          />
        </CCol>
        <CCol col="12" lg="6" class="mb-3">
          <div>
            <TMessage
              content="Auto add contract"
              class="mb-2 pb-1"
              bold
            />
            <TSwitch
              :checked="data.auto_add_contract"
              @change="data.auto_add_contract = $event"
            />
          </div>
          <TInputMoney
            v-if="withDeclaration"
            placeholder="Enter"
            label="Insurance declaration"
            class="my-3"
            @update:value="data.insurance_declaration = $event"
            :currency="data.service_fee_currency_id"
          />
          <TInputMoney
            v-if="withDeclaration"
            placeholder="Enter"
            label="Special declaration"
            class="my-3"
            @update:value="data.special_declaration = $event"
            :currency="data.service_fee_currency_id"
          />
        </CCol>
        <CCol col="12" lg="6" class="mb-3" v-if="withTracking">
          <SInputTracking
            :value.sync="tracking_code"
            @input="tracking_code = $event"
            class="my-3"
            @keydown.enter.native="addTracking"
          />
          <TButton
            class="d-flex ml-auto mb-3"
            content="Add"
            icon="cil-plus"
            :disabled="!tracking_code"
            @click="addTracking"
          />
          <div class="d-flex flex-wrap">
            <div
              v-for="tracking in tracking_list"
              :key="tracking"
              class="d-flex align-items-center shadow rounded p-2 mr-2 mb-2 border-info"
            >
              <TMessage class="h5 m-0" :content="tracking" noTranslate />
              <TButtonRemove
                class="ml-2"
                noConfirm
                @click="removeTracking(tracking)"
              />
            </div>
          </div>
        </CCol>
      </CRow>
      <div v-if="withProduct">
        <TButton
          content="Add product"
          @click="showAddItem = true"
          :messageOptions="{ bold: true }"
          variant="outline"
          class="mb-2"
          icon="cisPlus"
        />
        <TTableAdvance
          v-if="listProduct.length > 0"
          :items="listProduct"
          :fields="productFields"
          store=""
          resource=""
          noFilter
          noPaginate
          noClickRow
        >
          <template #append-actions="{ item }">
            <CCol class="px-1 m-1" col="12">
              <TButtonRemove noConfirm @click="remove(item.product_id)" />
            </CCol>
          </template>
          <template #items="{ item }">
            <td>
              <SCardProductItems :item="item.product" />
            </td>
          </template>
          <template #price="{ item }">
            <td>
              <TMessageMoney
                :amount="item.price"
                editable
                :decimalLimit="6"
                @change="item.price = $event"
                :currency="data.purchase_cost_currency_id || 'JPY'"
              />
            </td>
          </template>
          <template #quantity="{ item }">
            <td>
              <TMessageNumber
                :value="item.quantity"
                editable
                @change="item.quantity = $event"
              />
            </td>
          </template>
          <template #tax_percent="{ item }">
            <td>
              <TMessagePercent
                :value="item.tax_percent"
                editable
                @change="item.tax_percent = $event"
              />
            </td>
          </template>
          <template #expected_delivery="{ item }">
            <td>
              <TMessageDateTime
                :content="item.expected_delivery"
                dateOnly
                editable
                @change="item.expected_delivery = $event"
              />
            </td>
          </template>
          <template #discount_tax_per_tax_percent="{ item }">
            <td>
              <TMessagePercent
                :value="item.discount_tax_per_tax_percent"
                editable
                @change="item.discount_tax_per_tax_percent = $event"
              />
            </td>
          </template>
          <template #note="{ item }">
            <td>
              <TMessageText
                :value="item.note"
                editable
                @change="item.note = $event"
              />
            </td>
          </template>
        </TTableAdvance>
      </div>
      <SModalAddItem
        :show.sync="showAddItem"
        @click-add="add"
        :key="key"
        :currency_id="data.purchase_cost_currency_id || 'JPY'"
      />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      required: true,
      default: "",
    },
    multiProduct: {
      type: Boolean,
      default: true,
    },
    withProduct: {
      type: Boolean,
      default: false,
    },
    withTracking: {
      type: Boolean,
      default: false,
    },
    withDeclaration: {
      type: Boolean,
      default: false,
    },
    withAttachment: {
      type: Boolean,
      default: false,
    },
    withPayFreightCharge: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      shipmentFields: [
        { key: "consignee", label: "Consignee" },
        { key: "full_address", label: "Address" },
        { key: "tel", label: "Tel" },
        { key: "sender_name", label: "Sender name" },
        { key: "sender_full_address", label: "Address" },
        { key: "sender_tel", label: "Tel" },
        { key: "note", label: "Note" },
      ],
      productFields: [
        { key: "_", _style: "width: 45px" },
        { key: "items", label: "Product" },
        {
          key: "price",
          label: "Price",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "quantity",
          label: "Quantity",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "tax_percent",
          label: "Tax percent",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "discount_tax_per_tax_percent",
          label: "Discount tax",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "expected_delivery",
          label: "Desired delivery",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 350px; min-width: 350px",
        },
      ],
      listProduct: [],
      shipment_info: null,
      data: {
        shipment_method_id: "sea",
        note: null,
        customer_id: "",
        purchase_cost_currency_id: "JPY",
        service_fee_currency_id: "VND",
        sales_organization_id: "tomoni-jp",
        transport_organization_id: "tomoni-vn",
        auto_add_contract: true,
      },
      showAddItem: false,
      disabled: false,
      key: "add-item",
      //shipment
      tracking_code: null,
      tracking_list: [],
      //payment attachment
      file: [],
    };
  },
  watch: {
    "data.customer_id"() {
      this.shipment_info = null;
    },
    defaultValue: {
      immediate: true,
      handler(o) {
        this.data = {
          ...this.data,
          ...o,
        };
      },
    },
  },
  methods: {
    create() {
      this.disabled = true;
      let data = {
        "shipment_info[consignee]": this.shipment_info?.consignee,
        "shipment_info[address]": this.shipment_info?.address_detail,
        "shipment_info[tel]": this.shipment_info?.tel,
        "shipment_info[ward_id]": this.shipment_info?.ward_id,
        "shipment_info[note]": this.shipment_info?.note,
        "shipment_info[sender_name]": this.shipment_info?.sender_name
          ? this.shipment_info?.sender_name
          : this.shipment_info?.consignee,
        "shipment_info[sender_tel]": this.shipment_info?.sender_tel
          ? this.shipment_info?.sender_tel
          : this.shipment_info?.tel,
        "shipment_info[sender_address]": this.shipment_info?.sender_address
          ? this.shipment_info?.sender_address
          : this.shipment_info?.address,
        "shipment_info[sender_ward_id]": this.shipment_info?.sender_ward_id
          ? this.shipment_info?.sender_ward_id
          : this.shipment_info?.ward_id,
        ...this.data,
        auto_add_contract: this.data.auto_add_contract ? "1" : "0",
      };
      if (this.withAttachment) {
        data = { ...data, "attachments[0][file]": this.file };
      }
      if (this.withProduct) {
        this.listProduct.forEach((x, i) => {
          let productItemConvert = this.lodash.omit(x, ["product"]);

          const productItem = this.lodash.mapKeys(
            productItemConvert,
            (value, key) => {
              return `items[${i}][${key}]`;
            }
          );
          data = { ...data, ...productItem };
        });
      }
      if (this.withTracking) {
        this.tracking_list.forEach((tracking, i) => {
          const tracking_code = { [`tracking_codes[${i}]`]: tracking };
          data = { ...data, ...tracking_code };
        });
      }

      const dataClean = {
        ...this.lodash.pickBy(data, this.lodash.identity),
        shipment_method_id: this.data.shipment_method_id,
      };

      this.$store
        .dispatch(`order.${this.store}.create`, dataClean)
        .then((data) => {
          if (this.store == "order_shipment") {
            if (data.length > 1)
              this.$store.dispatch("order.order_shipment.fetch");
            this.$router.push({ path: `${data[0].id}` });
          } else this.$router.push({ path: `${data.id}` });
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    // add product: retail - auction
    add(product) {
      const item = { ...product };
      if (this.multiProduct) {
        const itemIndex = this.listProduct.findIndex(
          (x) => x.product_id === item.product_id
        );
        if (itemIndex === -1) {
          this.listProduct.push(item);
        }
      } else {
        this.listProduct = [item];
      }

      this.showAddItem = false;
      this.key = this.lodash.resetKey("add-item");
    },
    remove(id) {
      const itemIndex = this.listProduct.findIndex((x) => x.product_id === id);
      this.listProduct.splice(itemIndex, 1);
    },
    updateAddress(data, full_address) {
      this.shipment_info.ward_id = data.ward_id;
      this.shipment_info.address_detail = data.address;
      this.shipment_info.full_address = data.address + ", " + full_address;
    },
    updateSenderAddress(data, full_address) {
      this.shipment_info.sender_ward_id = data.ward_id;
      this.shipment_info.sender_address = data.address;
      this.shipment_info.sender_full_address =
        data.address + ", " + full_address;
    },
    // shipment
    addTracking() {
      if (!this.tracking_code) return;
      const itemIndex = this.tracking_list.findIndex(
        (x) => x === this.tracking_code
      );
      if (itemIndex == -1) {
        this.tracking_list.push(this.tracking_code);
        this.tracking_code = null;
      } else this.tracking_code = null;
    },
    removeTracking(tracking) {
      const itemIndex = this.tracking_list.findIndex((x) => x == tracking);
      this.tracking_list.splice(itemIndex, 1);
    },
  },
};
</script>
